import type { ContextData } from '@wirechunk/schemas/context-data/context-data';
import type { BooleanExpression } from '@wirechunk/schemas/expressions/boolean-expression';
import type { AgreementType } from '../../graphql-api-enums.ts';
import type { CssProperties } from '../css-properties.ts';
import type { ElementTag } from '../element-tag.ts';

export type Components = {
  ActiveOrgSiteGuard: ActiveOrgSiteGuardComponent;
  ActiveOrgSitePageGuard: ActiveOrgSitePageGuardComponent;
  AgreementsGuard: AgreementGuardComponent;
  // TODO: Rename to CheckboxInput.
  BooleanInput: BooleanInputComponent;
  Box: BoxComponent;
  Button: ButtonComponent;
  CalendlyEmbed: CalendlyEmbedComponent;
  CompensationCalculator: CompensationCalculatorComponent;
  ConditionalLogic: ConditionalLogicComponent;
  Course: CourseComponent;
  CurrentYear: ComponentSpec<'CurrentYear'>;
  CustomizeOrganizationSite: CustomizeOrgSiteComponent;
  Dashboard: DashboardComponent;
  CollapsiblePanel: CollapsiblePanelComponent;
  CompletableVideoInput: CompletableVideoInputComponent;
  DataInputTable: DataInputTableComponent;
  DateInput: DateInputComponent;
  // TODO: Rename to CustomComponent.
  Design: DesignComponent;
  Document: DocumentComponent;
  DropdownInput: DropdownInputComponent;
  Form: FormComponent;
  FormatProps: FormatPropsComponent;
  FormConfirmationMessage: FormConfirmationMessageComponent;
  FormStep: FormStepComponent;
  HelpTicketsPortal: ComponentSpec<'HelpTicketsPortal'>;
  HTML: HTMLComponent;
  Icon: IconComponent;
  InputValidationErrorMessage: ComponentSpec<'InputValidationErrorMessage'>;
  Image: ImageComponent;
  LessonNotesField: LessonNotesFieldComponent;
  Link: LinkComponent;
  OrgSitePageLink: OrgSitePageLinkComponent;
  Map: MapComponent;
  MarketingCalculator: MarketingCalculatorComponent;
  MoveUserPlan: MoveUserPlanComponent;
  MultiOrgView: MultiOrgViewComponent;
  // TODO: Rename to QuizMultipleChoiceInput.
  MultipleChoiceInput: MultipleChoiceInputComponent;
  // TODO: Rename to QuizMultiSelectInput.
  MultiSelectInput: MultiSelectInputComponent;
  NumberInput: NumberInputComponent;
  OrderPage: OrderPageComponent;
  // TODO: Rename to OrgBillingSettings.
  OrganizationBilling: ComponentSpec<'OrganizationBilling'>;
  OrganizationSiteForms: ComponentSpec<'OrganizationSiteForms'>;
  // TODO: Rename to OrgSiteFormEntries.
  OrganizationSiteLeads: ComponentSpec<'OrganizationSiteLeads'>;
  OrgBillingSettings: ComponentSpec<'OrgBillingSettings'>;
  OrgSitePages: OrgSitePagesComponent;
  OrgSiteReviews: ComponentSpec<'OrgSiteReviews'>;
  // TODO: Rename to OrgMembersDashboard.
  OrganizationTeam: OrgTeamComponent;
  Padding: PaddingComponent;
  Page: ComponentSpec<'Page'>;
  PhoneLink: PhoneLinkComponent;
  PlainText: PlainTextComponent;
  Popover: PopoverComponent;
  ProvideProps: ProvidePropsComponent;
  RadioGroupInput: RadioGroupInputComponent;
  // TODO: Rename to RadioGroupInputItem.
  RadioGroupInputButton: RadioGroupInputButtonComponent;
  RemoteComponent: RemoteComponentComponent;
  RequestPasswordReset: ComponentSpec<'RequestPasswordReset'>;
  ResetPassword: ResetPasswordComponent;
  Review: ReviewComponent;
  ScoreMyCall: ScoreMyCallComponent;
  SignInGuard: ComponentSpec<'SignInGuard'>;
  SiteLogo: SiteLogoComponent;
  SitePrivacyPolicy: ComponentSpec<'SitePrivacyPolicy'>;
  SiteTermsOfUse: ComponentSpec<'SiteTermsOfUse'>;
  // TODO: Rename to Sequence.
  Stage: StageComponent;
  // TODO: Rename to SequenceStageBody.
  StageBody: StageBodyComponent;
  StageFileInput: StageFileInputComponent;
  // TODO: Rename to SequenceStageName.
  StageName: ComponentSpec<'StageName'>;
  // TODO: Rename to ProductItemGuard.
  SubscriptionTagGuard: SubscriptionTagGuardComponent;
  Text: TextComponent;
  TextInput: TextInputComponent;
  TextareaInput: TextareaInputComponent;
  Typeform: TypeformComponent;
  UserProfile: ComponentSpec<'UserProfile'>;
  VerifiedEmailAddressGuard: VerifiedEmailAddressGuardComponent;
  VerifyEmailAddress: ComponentSpec<'VerifyEmailAddress'>;
  Video: VideoComponent;
  // TODO: Rename to ViewSequenceStagesButton. Change this to a dialog with a Trigger slot.
  ViewPlanStagesButton: ViewPlanStagesButtonComponent;
  WidthContainer: WidthContainerComponent;
};

export type ComponentType = keyof Components;

export type ComponentBase<T> = {
  type: T;
  id: string;
};

export type ComponentSpec<T extends ComponentType> = ComponentBase<T> &
  Children &
  ChildrenOrLabel &
  ClassName &
  ConditionalStyles &
  DataInputTableColumnOptions &
  DefaultValue &
  ElementId &
  Label &
  MaxLength &
  Name &
  CustomProps &
  Required &
  Slot &
  Styles &
  Tag &
  Value;

export type Children = {
  children?: Component[] | null;
};

export type ChildrenOrLabel = {
  childrenOrLabel?: 'children' | 'label' | null;
};

export type ClassName = {
  className?: string | null;
};

export enum DataSource {
  Direct = 'Direct',
  Prop = 'Prop',
}

type DataSourceSpecDirect<DirectType> = DirectType & {
  type: DataSource.Direct;
};

type DataSourceSpecProp = {
  type: DataSource.Prop;
  // The prop name.
  name: string;
};

export type DataSourceSpec<DirectType> = DataSourceSpecDirect<DirectType> | DataSourceSpecProp;

export type DataInputTableColumnOptions = {
  dataInputTableColumnOptions?: {
    // className is used as the className for the Column in the table.
    className?: string | null;
    header?: string | null;
    width?: string | null;
  } | null;
};

export type ElementId = {
  elementId?: string | null;
};

export type Label = {
  // TODO: Migrate to using only StringValueSource.
  label?: string | StringValueSource | null;
  labelStyles?: Styles | null;
};

export type MaxLength = {
  maxLength?: number | null;
};

export type Name = {
  name?: string | null;
};

export type CustomProps = {
  customProps?: ContextData | null;
};

export type Required = {
  required?: boolean | null;
};

export type Tag = {
  tag?: ElementTag | null;
};

export enum ConditionalLogicSlotKey {
  True = 'True',
  False = 'False',
}

export enum SubscriptionTagGuardSlotKey {
  AccessAllowed = 'AccessAllowed',
  AccessDenied = 'AccessDenied',
}

export type SlotKey = ConditionalLogicSlotKey | SubscriptionTagGuardSlotKey;

export type Slot = {
  slot?: SlotKey | null;
};

export type BooleanValueSource = DataSourceSpec<{
  value?: boolean | null;
}>;

// A string value can be used for a TextInput, a TextareaInput, a DropdownInput, or a RadioGroupInput.
export type StringValueSource =
  | DataSourceSpec<{
      value?: string | null;
    }>
  | { type: 'PageTitle' }
  | { type: 'SiteName' };

export type NumberValueSource = DataSourceSpec<{
  value?: number | null;
}>;

export type DefaultValue = {
  defaultValue?: BooleanValueSource | NumberValueSource | StringValueSource | null;
};

export type Value = {
  value?: BooleanValueSource | NumberValueSource | StringValueSource | null;
};

export type BaseStyles = {
  styles?: CssProperties;
  smStyles?: CssProperties;
  mdStyles?: CssProperties;
  lgStyles?: CssProperties;
  xlStyles?: CssProperties;
  xxlStyles?: CssProperties;
};

export type Styles = BaseStyles & {
  hoverStyles?: BaseStyles;
  focusVisibleStyles?: BaseStyles;
};

export type ConditionAndStyles = {
  // A unique string for the elements in the array where this object is found.
  key: string;
  condition: BooleanExpression;
  styles: Styles;
};

export type ConditionalStyles = {
  conditionalStyles?: ConditionAndStyles[] | null;
};

export type Component = Components[ComponentType];

export type ActiveOrgSiteGuardComponent = ComponentSpec<'ActiveOrgSiteGuard'>;

export type ActiveOrgSitePageGuardComponent = ComponentSpec<'ActiveOrgSitePageGuard'> & {
  pageTemplateId?: string | null;
  pageMissingMessage?: string | null;
};

export type AgreementGuardComponent = ComponentSpec<'AgreementsGuard'> & {
  requiredAgreementTypes?: AgreementType[] | null;
};

export type CompensationCalculatorComponent = ComponentSpec<'CompensationCalculator'>;

export type CustomizeOrgSiteComponent = ComponentSpec<'CustomizeOrganizationSite'> & {
  privacyPolicyPageTemplateId?: string | null;
  termsOfUsePageTemplateId?: string | null;
};

export type DashboardNavItem = {
  id: string;
  label: string;
  url: string;
  productItem: string | null;
  roles: string[];
};

export type DashboardComponent = ComponentSpec<'Dashboard'> & {
  mainNavItems?: DashboardNavItem[] | null;
  extraNavItems?: DashboardNavItem[] | null;
};

export type DataInputTableComponent = ComponentSpec<'DataInputTable'> & {
  readonly?: boolean | null;
};

export type MarketingCalculatorComponent = ComponentSpec<'MarketingCalculator'>;

export type OrderPageCheckoutOption = {
  subscriptionPlanId: string;
  // If not set, the condition is assumed to be satisfied.
  condition?: BooleanExpression | null;
};

export enum OrderFormPromoCodeType {
  Stripe = 'Stripe',
  Custom = 'Custom',
}

export type OrderPageComponent = ComponentSpec<'OrderPage'> & {
  enablePromoCode?: OrderFormPromoCodeType | null;
  checkoutOptions?: OrderPageCheckoutOption[] | null;
};

export type ScoreMyCallComponent = ComponentSpec<'ScoreMyCall'>;

// An "img" element.
// When the site name is rendered as fallback, it is rendered inside a div.
export type SiteLogoComponent = ComponentSpec<'SiteLogo'> & {
  // Defaults to the site name.
  alt?: string | null;
  // Defaults to false.
  fallbackSiteName?: boolean;
};

export type StageComponent = ComponentSpec<'Stage'> & {
  // TODO: Rename to sequenceId.
  planId?: string | null;
  sequenceId?: string | null;
  enableWrappedStageBody?: boolean | null;
};

// This component does not add any HTML markup. So styling and classes are ignored.
export type StageBodyComponent = ComponentSpec<'StageBody'>;

export enum SubscriptionTagGuardAccessDeniedMode {
  CustomMessage = 'CustomMessage',
  DefaultMessage = 'DefaultMessage',
  Empty = 'Empty',
}

// Guard the rendering of children with a subscription tag that the user must have. Children components without
// a "slot" property are considered to be the guarded content as if they have slot = AccessAllowed. In other words,
// the default slot key for children is AccessAllowed.
export type SubscriptionTagGuardComponent = ComponentSpec<'SubscriptionTagGuard'> & {
  productItem?: string | null;
  // Deprecated. Use productItem instead.
  tag?: string | null;
  // The default accessDeniedMode is DefaultMessage. A custom message is defined through children
  // with an AccessDenied slot key.
  accessDeniedMode?: SubscriptionTagGuardAccessDeniedMode | null;
};

export enum TextInputComponentFormat {
  None = 'None',
  Email = 'Email',
  Phone = 'Phone',
  // TODO: Implement validation for URLs.
  Url = 'Url',
}

export type TextInputComponent = ComponentSpec<'TextInput'> & {
  placeholder?: string | null;
  format?: TextInputComponentFormat | null;
};

export type TextareaInputComponent = ComponentSpec<'TextareaInput'> & {
  placeholder?: string | null;
  maxLength?: number | null;
};

export type DateInputComponentDateSpec =
  | { type: 'today' }
  // For 'date' the value is a date in the ISO 8601 format.
  | { type: 'date'; value: string };

export enum DateInputComponentStyle {
  Calendar = 'Calendar',
  ThreeFields = '3Fields',
}

// A DateInputComponent stores values in the YYYY-MM-DD format.
export type DateInputComponent = ComponentSpec<'DateInput'> & {
  placeholder?: string | null;
  clearable?: boolean | null;
  style?: DateInputComponentStyle | null;
  minimumDate?: DateInputComponentDateSpec | null;
  maximumDate?: DateInputComponentDateSpec | null;
  monthPlaceholder?: string | null;
  dayPlaceholder?: string | null;
  yearPlaceholder?: string | null;
};

export type DropdownOption = {
  key: number;
  // value is displayed and is how an option is serialized.
  value: string;
};

export type DropdownInputComponent = ComponentSpec<'DropdownInput'> & {
  // Clearable is ignored if the input is required. When the input is not required, it may or may not be clearable.
  // The default is false.
  clearable?: boolean | null;
  placeholder?: string | null;
  options?: DropdownOption[] | null;
};

export enum NumberInputFormat {
  Number = 'Number',
  Currency = 'Currency',
  Integer = 'Integer',
}

export type NumberInputComponent = ComponentSpec<'NumberInput'> & {
  maxDecimalDigits?: number | null;
  placeholder?: string | null;
  format?: NumberInputFormat | null;
};

// If value set to a string and is not an empty string, it is used as the value for the input when the box is checked.
// Otherwise, true or false is set as the value.
export type BooleanInputComponent = ComponentSpec<'BooleanInput'>;

export type CompletableVideoInputComponent = ComponentSpec<'CompletableVideoInput'> & {
  src?: string | null;
  showHeader?: boolean | null;
  // By default, collapseCompleted is false.
  collapseCompleted?: boolean | null;
  // Default initialCollapsed is false.
  initiallyCollapsed?: boolean | null;
};

export type ConditionalLogicComponent = ComponentSpec<'ConditionalLogic'> & {
  condition?: BooleanExpression | null;
};

export type CourseComponent = ComponentSpec<'Course'> & {
  // If set, a Course is used instead of the legacy Plan.
  courseId?: string | null;
  planId?: string | null;
  enableNotes?: boolean | null;
  emptyNotesMessage?: string | null;
};

export type MultipleChoiceInputComponent = ComponentSpec<'MultipleChoiceInput'> & {
  question?: string | null;
  choices?: string[] | null;
  answerIndex?: number | null;
};

export type MultiSelectInputComponent = ComponentSpec<'MultiSelectInput'> & {
  question?: string | null;
  // TODO: Restructure into an array of objects.
  choices?: string[] | null;
  answerIndices?: number[] | null;
};

export enum FileTypeCategory {
  Audio = 'Audio',
  Image = 'Image',
  Video = 'Video',
}

export type StageFileInputComponent = ComponentSpec<'StageFileInput'> & {
  allowedTypes?: FileTypeCategory[] | null;
};

export type OrgSitePageLinkComponent = ComponentSpec<'OrgSitePageLink'> & {
  pageTemplateId?: string | null;
  displayPlainText?: boolean | null;
  linkText?: string | null;
};

export type OrgSitePagesComponent = ComponentSpec<'OrgSitePages'> & {
  // By default, no page template ID filter is applied.
  pageTemplates?:
    | { filter: 'include'; ids: string[] }
    | { filter: 'exclude'; ids: string[] }
    | null;
  defaultPageTemplatePreviewImageUrl?: string | null;
};

export type OrgTeamComponent = ComponentSpec<'OrganizationTeam'> & {
  showUserTrainingProgress?: boolean | null;
  userTrainingProgressCourseIds?: string[] | null;
  userTrainingProgressContentPlanIds?: string[] | null;
};

export type BoxComponent = ComponentSpec<'Box'>;

export type ButtonOnClick =
  | { type: 'doNothing' }
  | { type: 'submitForm' }
  | { type: 'resetForm' }
  | { type: 'goBack' }
  | { type: 'goBackFormStep' }
  | { type: 'navigate'; url: string; isExternal?: boolean; openInNewTab?: boolean };

export type ButtonComponent = ComponentSpec<'Button'> & {
  // text is deprecated. Use label instead. TODO: Or, better, children?
  text?: string | null;
  // If onClick is null or undefined, nothing happens on click.
  onClick?: ButtonOnClick | null;
  icon?: string | null;
  iconPosition?: ButtonIconPosition | null;
};

// TODO: Remove
export enum ButtonIconPosition {
  Left = 'left',
  Right = 'right',
}

export type CalendlyEmbedUrlSource = DataSourceSpec<{
  url?: string | null;
}>;

export type CalendlyEmbedUrlQueryParameterValueSource = DataSourceSpec<{
  value?: string | null;
}>;

export type CalendlyEmbedQueryParameter = {
  id: string;
  parameter: string;
  value: CalendlyEmbedUrlQueryParameterValueSource;
};

export type CalendlyEmbedComponent = ComponentSpec<'CalendlyEmbed'> & {
  url?: CalendlyEmbedUrlSource | null;
  queryParameters?: CalendlyEmbedQueryParameter[] | null;
};

export type CollapsiblePanelComponent = ComponentSpec<'CollapsiblePanel'> & {
  header?: string | null;
  // TODO: Implement this option.
  expandedByDefault?: boolean;
};

export type DesignComponent = ComponentSpec<'Design'> & {
  customComponentId?: string | null;
  // Deprecated
  designId?: string | null;
};

export type DocumentComponent = ComponentSpec<'Document'> & {
  documentId?: string | null;
};

export type FormComponent = ComponentSpec<'Form'> & {
  formId?: string;
};

export const formatPropModes = ['Transform', 'Template'] as const;

export type FormatPropMode = (typeof formatPropModes)[number];

export const formatPropFormats = [
  'Phone number (###) ###-####',
  'Phone number ###-###-####',
  'U.S. State abbreviation spelled out',
] as const;

export type FormatPropFormat = (typeof formatPropFormats)[number];

export type FormatProp = {
  id: string;
  outputPropName: string;
  // If mode is 'Transform', the output is a simple function of the input using a built-in function.
  // If mode is 'Template', the output comes from a user-defined template.
  // Temporaryy: default mode is transform (need to backfill).
  mode?: FormatPropMode | null;
  // Used only in transform mode.
  inputPropName: string;
  // Used only in transform mode.
  format?: FormatPropFormat | null;
  // Used only in template mode. A LiquidJS template.
  template?: string | null;
};

export type FormatPropsComponent = ComponentSpec<'FormatProps'> & {
  props?: FormatProp[] | null;
};

export type FormConfirmationMessageComponent = ComponentSpec<'FormConfirmationMessage'>;

export type FormStepComponent = ComponentSpec<'FormStep'>;

export type HTMLComponent = ComponentSpec<'HTML'> & {
  html?: string | null;
};

export type MapFocus = DataSourceSpec<{
  googlePlaceId?: string | null;
}>;

export type MapComponent = ComponentSpec<'Map'> & {
  focus?: MapFocus;
};

export type MoveUserPlanComponent = ComponentSpec<'MoveUserPlan'> & {
  previousButtonLabel?: string | null;
  nextButtonLabel?: string | null;
};

export type MultiOrgViewComponent = ComponentSpec<'MultiOrgView'> & {
  showUserTrainingProgress?: boolean | null;
  userTrainingProgressCourseIds?: string[] | null;
  userTrainingProgressContentPlanIds?: string[] | null;
};

export type IconComponent = ComponentSpec<'Icon'> & {
  icon?: string | null;
};

export type ImageSource =
  | DataSourceSpec<{
      url: string;
    }>
  | { type: 'SiteLogoUrl' };

export type ImageComponent = ComponentSpec<'Image'> & {
  alt?: string | null;
  src?: ImageSource | null;
};

export type LessonNotesFieldComponent = ComponentSpec<'LessonNotesField'>;

export enum PaddingAmount {
  StandardPageBody = 'StandardPageBody',
  Narrow = 'Narrow',
  Medium = 'Medium',
  Wide = 'Wide',
  XWide = 'XWide',
}

export type PaddingSource = DataSourceSpec<{
  padding?: PaddingAmount | null;
}>;

export type PaddingComponent = ComponentSpec<'Padding'> & {
  padding?: PaddingSource | null;
};

export type PhoneLinkPhoneNumber = DataSourceSpec<{
  phoneNumber?: string | null;
}>;

export type PhoneLinkComponent = ComponentSpec<'PhoneLink'> & {
  phoneNumber?: PhoneLinkPhoneNumber;
};

export type LinkComponent = ComponentSpec<'Link'> & {
  // Must be an absolute path without a host.
  to?: string;
  // isExternal defaults to false.
  isExternal?: boolean;
  openInNewTab?: boolean;
};

export type PlainTextSource =
  | DataSourceSpec<{
      text?: string | null;
    }>
  | PlainTextSourceSiteName
  | PlainTextSourcePageTitle;

type PlainTextSourcePageTitle = {
  type: 'PageTitle';
};

type PlainTextSourceSiteName = {
  type: 'SiteName';
};

export type PlainTextComponent = ComponentSpec<'PlainText'> & {
  text?: PlainTextSource | null;
};

export enum MixerEventType {
  Click = 'Click',
  InputChange = 'InputChange',
}

export type Trigger = {
  // id is used for internal purposes (e.g., rendering).
  id: string;
  type: MixerEventType;
  // If not set, the condition is assumed to be satisfied.
  condition?: BooleanExpression | null;
};

export type PopoverComponent = ComponentSpec<'Popover'> & {
  showTriggers?: Trigger[] | null;
  hideTriggers?: Trigger[] | null;
};

export type ProvidePropValueSource =
  | DataSourceSpecDirect<ProvidePropValueSourceDirect>
  | ProvidePropValueSourceCustomField
  | ProvidePropValueSourceQueryParameter;

export enum ProvidePropValueSourceDirectType {
  Number = 'Number',
  String = 'String',
}

export type ProvidePropValueSourceDirectValue =
  | { type: ProvidePropValueSourceDirectType.String; value: string | null }
  | { type: ProvidePropValueSourceDirectType.Number; value: number | null };

export type ProvidePropValueSourceDirect = {
  value: ProvidePropValueSourceDirectValue | null;
};

export type ProvidePropValueSourceCustomField = {
  type: 'CustomField';
  // For a page template being previewed, when the object is 'Page' the custom fields are taken instead from the page template.
  object?: 'Site' | 'Page' | 'User' | null;
  fieldName?: string | null;
};

export type ProvidePropValueSourceQueryParameter = {
  type: 'QueryParameter';
  parameter?: string | null;
};

export type ProvideProp = {
  id: string;
  name: string;
  source: ProvidePropValueSource;
};

export type ProvidePropsComponent = ComponentSpec<'ProvideProps'> & {
  props?: ProvideProp[] | null;
};

export type RadioGroupInputComponent = ComponentSpec<'RadioGroupInput'> & {
  // Defaults to indicator.
  displayType?: 'indicators' | 'cards' | null;
};

// A RadioGroupInputButton is rendered only if value is not null and not undefined.
export type RadioGroupInputButtonComponent = ComponentSpec<'RadioGroupInputButton'>;

export type RemoteComponentComponent = ComponentSpec<'RemoteComponent'> & {
  extensionName: string;
  componentName: string;
};

export type ResetPasswordComponent = ComponentSpec<'ResetPassword'> & {
  mode?: 'create' | 'reset' | null;
};

export type ReviewGooglePlaceSource = DataSourceSpec<{
  googlePlaceId?: string | null;
}>;

export type ReviewComponent = ComponentSpec<'Review'> & {
  googlePlace?: ReviewGooglePlaceSource | null;
  selectStarsLabel?: string | null;
  reviewFieldPlaceholder?: string | null;
};

export enum TwoColumnWidths {
  // 1/2 1/2
  OneHalfOneHalf = 'OneHalfOneHalf',
  // 1/4 3/4
  OneQuarterThreeQuarters = 'OneQuarterThreeQuarters',
  // 1/3 2/3
  OneThirdTwoThirds = 'OneThirdTwoThirds',
  // 3/4 1/4
  ThreeQuartersOneQuarter = 'ThreeQuartersOneQuarter',
  // 2/3 1/3
  TwoThirdsOneThird = 'TwoThirdsOneThird',
  // 100% 100%
  FullFull = 'FullFull',
}

export enum ThreeColumnWidths {
  // 1/2 1/4 1/4
  OneHalfOneQuarterOneQuarter = 'OneHalfOneQuarterOneQuarter',
  // 1/4 1/2 1/4
  OneQuarterOneHalfOneQuarter = 'OneQuarterOneHalfOneQuarter',
  // 1/4 1/4 1/2
  OneQuarterOneQuarterOneHalf = 'OneQuarterOneQuarterOneHalf',
  // 1/3 1/3 1/3
  OneThirdOneThirdOneThird = 'OneThirdOneThirdOneThird',
  // 100% 100% 100%
  FullFullFull = 'FullFullFull',
}

export type TextContent = {
  // A Quill Delta JSON object.
  delta: string;
};

export type TextComponent = ComponentSpec<'Text'> & {
  content?: TextContent | null;
};

export type TypeformComponent = ComponentSpec<'Typeform'> & {
  formId?: string | null;
};

export type VerifiedEmailAddressGuardComponent = ComponentSpec<'VerifiedEmailAddressGuard'>;

export type VideoComponent = ComponentSpec<'Video'> & {
  src?: string | null;
};

export type ViewPlanStagesButtonComponent = ComponentSpec<'ViewPlanStagesButton'> & {
  dialogHeader?: string | null;
  stageNameTableHeader?: string | null;
};

export enum Width {
  Medium = 'Medium',
  Large = 'Large',
  XLarge = 'XLarge',
  XXLarge = 'XXLarge',
}

export type WidthContainerComponent = ComponentSpec<'WidthContainer'> & {
  // Default width is Large.
  width?: Width | null;
};

export type NonEmptyChildren = {
  children: [Component, ...Component[]];
};
