import { AnalyticsContext } from '@wirechunk/extension-toolkit/web/analytics-context';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { Component } from '@wirechunk/lib/mixer/types/components.ts';
import { clsx } from 'clsx';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import type { FunctionComponent } from 'react';
import { Fragment, use, useState } from 'react';
import { FormField } from '../../form-field/form-field.tsx';
import { Label } from '../../label/label.tsx';
import styles from './CompensationCalculator.module.css';

const positiveNegativeClass = (value: number) =>
  value > 0 ? 'text-color-success-dark' : value < 0 ? 'text-color-danger-dark' : undefined;

type ScenarioInput = {
  autoPremium: number | null;
  homePremium: number | null;
  teamMemberCommissionPercentage: number | null;
  teamMemberBaseSalary: number | null;
  payrollTaxPercentage: number | null;
  agencyAutoCommissionPercentage: number | null;
  agencyHomeCommissionPercentage: number | null;
  agencyAutoRenewalCommissionPercentage: number | null;
  agencyHomeRenewalCommissionPercentage: number | null;
  clientRetentionPercentage: number | null;
};

type ScenarioCore = {
  autoPremium: number;
  homePremium: number;
  teamMemberCommissionRate: number;
  teamMemberBaseSalary: number;
  payrollTaxRate: number;
  agencyAutoCommissionRate: number;
  agencyHomeCommissionRate: number;
  agencyAutoRenewalCommissionRate: number;
  agencyHomeRenewalCommissionRate: number;
  clientRetentionRate: number;

  // Calculated

  autoPremiumRevenue: number;
  homePremiumRevenue: number;
  totalRevenue: number;
  teamMemberCommission: number;
  teamMemberTotalCompensation: number;
  totalExpenses1Month: number;
  netProfit1Month: number;
};

type AutoRenewalPeriod = '6mo' | 'year';

type Scenario6MonthRenewalNet = {
  autoRenewalPeriod: '6mo';
  revenue6MonthRenewalAuto: number;
  revenueYearRenewalAuto: number;
  revenueYearRenewalHome: number;
  netProfit6Months: number;
  netProfitYear: number;
};

type ScenarioYearRenewalNet = {
  autoRenewalPeriod: 'year';
  revenueYearRenewalAuto: number;
  revenueYearRenewalHome: number;
  netProfitYear: number;
};

type Scenario = ScenarioCore & (Scenario6MonthRenewalNet | ScenarioYearRenewalNet);

const computeScenario6MonthRenewal = (s: ScenarioCore): Scenario6MonthRenewalNet => {
  // The 6-month renewal includes only auto renewal. Note that we assume 6-month retention is 100%.
  const revenue6MonthRenewalAuto = s.autoPremium * s.agencyAutoRenewalCommissionRate;
  const revenueYearRenewalAuto = revenue6MonthRenewalAuto * s.clientRetentionRate;
  const revenueYearRenewalHome =
    s.homePremium * s.agencyHomeRenewalCommissionRate * s.clientRetentionRate;
  return {
    autoRenewalPeriod: '6mo',
    revenue6MonthRenewalAuto,
    revenueYearRenewalAuto,
    revenueYearRenewalHome,
    netProfit6Months: s.netProfit1Month + revenue6MonthRenewalAuto,
    netProfitYear:
      s.netProfit1Month +
      revenue6MonthRenewalAuto +
      revenueYearRenewalAuto +
      revenueYearRenewalHome,
  };
};

const computeScenarioYearRenewal = (s: ScenarioCore): ScenarioYearRenewalNet => {
  const revenueYearRenewalAuto =
    s.autoPremium * s.agencyAutoRenewalCommissionRate * s.clientRetentionRate;
  const revenueYearRenewalHome =
    s.homePremium * s.agencyHomeRenewalCommissionRate * s.clientRetentionRate;
  return {
    autoRenewalPeriod: 'year',
    revenueYearRenewalAuto,
    revenueYearRenewalHome,
    netProfitYear: s.netProfit1Month + revenueYearRenewalAuto + revenueYearRenewalHome,
  };
};

const defaultScenario = (): ScenarioInput => ({
  autoPremium: null,
  homePremium: null,
  teamMemberCommissionPercentage: null,
  teamMemberBaseSalary: null,
  payrollTaxPercentage: null,
  agencyAutoCommissionPercentage: null,
  agencyHomeCommissionPercentage: null,
  agencyAutoRenewalCommissionPercentage: null,
  agencyHomeRenewalCommissionPercentage: null,
  clientRetentionPercentage: null,
});

const autoPremiumDefault = 30_000;
const homePremiumDefault = 20_000;
const teamMemberCommissionPercentageDefault = 0.06;
const teamMemberBaseSalaryDefault = 3_000;
const payrollTaxPercentageDefault = 0.08;
const agencyAutoCommissionPercentageDefault = 0.1;
const agencyHomeCommissionPercentageDefault = 0.1;
const agencyAutoRenewalCommissionPercentageDefault = 0.1;
const agencyHomeRenewalCommissionPercentageDefault = 0.1;
const clientRetentionPercentageDefault = 0.9;

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

const currency = (value: number) => currencyFormat.format(value);

const placeholderPercentageFormat = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 0,
});

const percentageFormat = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 2,
});

const autoRenewalPeriodOptions: Array<{ label: string; value: AutoRenewalPeriod }> = [
  { label: '6 Months', value: '6mo' },
  { label: 'Annual', value: 'year' },
];

export const CompensationCalculator: FunctionComponent<Component> = (props) => {
  const analyticsContext = use(AnalyticsContext);
  const [isAddingVariation, setIsAddingVariation] = useState(true);
  const [state, setState] = useState<ScenarioInput>(defaultScenario);
  const [computedScenarios, setComputedScenarios] = useState<Scenario[]>([]);

  return (
    <div className={componentClassName(props)}>
      {computedScenarios.map((scenario, i) => (
        <div key={i} className={`${styles.scenario} mb-5 border-1 border-round-lg overflow-hidden`}>
          <div className="surface-ground p-3 flex flex-row align-items-center justify-content-between">
            <div className="font-bold">SCENARIO {i + 1} PROFIT/LOSS</div>
            <Button
              label="Delete"
              className="p-button-danger p-button-outlined p-button-sm background-white"
              onClick={() => {
                setComputedScenarios(computedScenarios.filter((_, j) => j !== i));
              }}
            />
          </div>
          <div>
            <FormField py="3" px="4">
              <Label htmlFor={`scenarioAutoRenewalPeriod${i}`}>Auto renewal period</Label>
              <Dropdown
                inputId={`scenarioAutoRenewalPeriod${i}`}
                className="w-11rem"
                value={scenario.autoRenewalPeriod}
                options={autoRenewalPeriodOptions}
                onChange={(e) => {
                  setComputedScenarios((computedScenarios) =>
                    computedScenarios.map((s, j) => {
                      if (j === i) {
                        if (e.value === '6mo') {
                          return {
                            ...s,
                            ...computeScenario6MonthRenewal(s),
                          };
                        }
                        if (e.value === 'year') {
                          return {
                            ...s,
                            ...computeScenarioYearRenewal(s),
                          };
                        }
                      }
                      return s;
                    }),
                  );
                }}
              />
            </FormField>
            <div
              className={clsx(
                styles.scenarioBody,
                scenario.autoRenewalPeriod === '6mo' ? styles.threeCol : styles.twoCol,
              )}
            >
              <div className="column-start-1 column-end-2 row-start-1 row-end-2">
                <div className={styles.header}>Month 1</div>
                <div className={styles.month1}>
                  <div className="border-bottom-1" />
                  <div className={`${styles.cell} border-bottom-1 font-medium`}>Premium</div>
                  <div className={`${styles.cell} border-bottom-1 font-medium`}>New revenue</div>
                  <div className={`${styles.cell} pl-3`}>Auto</div>
                  <div className={styles.cell}>{currency(scenario.autoPremium)}</div>
                  <div className={styles.cell}>{currency(scenario.autoPremiumRevenue)}</div>
                  <div className={`${styles.cell} pl-3`}>Home</div>
                  <div className={styles.cell}>{currency(scenario.homePremium)}</div>
                  <div className={styles.cell}>{currency(scenario.homePremiumRevenue)}</div>
                  <div className={`${styles.cell} pl-3 font-medium`}>Total</div>
                  <div className={`${styles.cell} font-medium`}>
                    {currency(scenario.autoPremium + scenario.homePremium)}
                  </div>
                  <div className={`${styles.cell} font-medium`}>
                    {currency(scenario.autoPremiumRevenue + scenario.homePremiumRevenue)}
                  </div>
                </div>
                <div>
                  <div className="border-bottom-1 font-bold pt-3 pb-1 text-center">Expenses</div>
                  <div className={styles.twoColGrid}>
                    <div className={`${styles.cell} pl-3`}>Base salary</div>
                    <div className={styles.cell}>{currency(scenario.teamMemberBaseSalary)}</div>
                    <div className={`${styles.cell} pl-3`}>
                      Commission ({percentageFormat.format(scenario.teamMemberCommissionRate)} of
                      premium)
                    </div>
                    <div className={styles.cell}>{currency(scenario.teamMemberCommission)}</div>
                    <div className={`${styles.cell} pl-3`}>
                      Payroll taxes ({percentageFormat.format(scenario.payrollTaxRate)} of base +
                      commission)
                    </div>
                    <div className={styles.cell}>
                      {currency(scenario.teamMemberTotalCompensation * scenario.payrollTaxRate)}
                    </div>
                    <div className={`${styles.cell} pl-3 font-medium`}>Total</div>
                    <div className={`${styles.cell} font-medium`}>
                      {currency(scenario.totalExpenses1Month)}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${styles.ytd} lg:column-start-1 lg:column-end-2 text-center font-bold`}
              >
                <span className="pr-2">Year-to-date net profit/loss:</span>
                <span className={positiveNegativeClass(scenario.netProfit1Month)}>
                  {currency(scenario.netProfit1Month)}
                </span>
              </div>
              <div className={`${styles.border} lg:column-start-2 lg:column-end-3`} />
              {scenario.autoRenewalPeriod === '6mo' && (
                <Fragment>
                  <div className="lg:column-start-3 lg:column-end-4 lg:row-start-1 lg:row-end-2">
                    <div className={styles.header}>6 Month Renewal</div>
                    <div className={styles.twoColGridWideValues}>
                      <div className="border-bottom-1" />
                      <div className={`${styles.cell} border-bottom-1 font-medium`}>
                        Renewal Revenue
                      </div>
                      <div className={`${styles.cell} pl-3`}>6-month auto renewal</div>
                      <div className={styles.cell}>
                        {currency(scenario.revenue6MonthRenewalAuto)}
                      </div>
                      <div className={`${styles.cell} pl-3 font-medium`}>Total</div>
                      <div className={`${styles.cell} font-medium`}>
                        {currency(scenario.revenue6MonthRenewalAuto)}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${styles.ytd} lg:column-start-3 lg:column-end-4 text-center font-bold`}
                  >
                    <span className="pr-2">Year-to-date net profit/loss:</span>
                    <span className={positiveNegativeClass(scenario.netProfit6Months)}>
                      {currency(scenario.netProfit6Months)}
                    </span>
                  </div>
                  <div className={`${styles.border} lg:column-start-4 lg:column-end-5`} />
                </Fragment>
              )}
              <div
                className={clsx(
                  'lg:row-start-1 lg:row-end-2',
                  scenario.autoRenewalPeriod === '6mo'
                    ? 'lg:column-start-5 lg:column-end-6'
                    : 'lg:column-start-3 lg:column-end-4',
                )}
              >
                <div className={styles.header}>Annual Renewal</div>
                <div className={styles.twoColGridWideValues}>
                  <div className="border-bottom-1" />
                  <div className={`${styles.cell} border-bottom-1 font-medium`}>
                    Renewal Revenue
                  </div>
                  <div className={`${styles.cell} pl-3`}>Annual auto renewal</div>
                  <div className={styles.cell}>{currency(scenario.revenueYearRenewalAuto)}</div>
                  <div className={`${styles.cell} pl-3`}>Annual home renewal</div>
                  <div className={styles.cell}>{currency(scenario.revenueYearRenewalHome)}</div>
                  <div className={`${styles.cell} pl-3 font-medium`}>Total</div>
                  <div className={`${styles.cell} font-medium`}>
                    {currency(scenario.revenueYearRenewalAuto + scenario.revenueYearRenewalHome)}
                  </div>
                </div>
              </div>
              <div
                className={clsx(
                  styles.ytd,
                  'text-center font-bold',
                  scenario.autoRenewalPeriod === '6mo'
                    ? 'lg:column-start-5 lg:column-end-6'
                    : 'lg:column-start-3 lg:column-end-4',
                )}
              >
                <span className="pr-2">Year-to-date net profit/loss:</span>
                <span className={positiveNegativeClass(scenario.netProfitYear)}>
                  {currency(scenario.netProfitYear)}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
      {isAddingVariation ? (
        <Fragment>
          {/* This extra div for centered layout. */}
          <div className={`${styles.containedWidth} mb-1`}>
            <div className="font-bold text-lg mb-4">Premium written by team member</div>
            <div className="mb-4 flex flex-column lg:flex-row lg:align-items-center">
              <Label htmlFor="autoPremium">Auto premium</Label>
              <InputNumber
                inputId="autoPremium"
                className="w-full lg:w-13rem"
                mode="currency"
                currency="USD"
                maxFractionDigits={0}
                placeholder={currency(autoPremiumDefault)}
                value={state.autoPremium}
                onChange={(e) => {
                  setState({
                    ...state,
                    autoPremium: e.value,
                  });
                }}
              />
            </div>
            <div className="mb-4 flex flex-column lg:flex-row lg:align-items-center">
              <Label htmlFor="homePremium">Home premium</Label>
              <InputNumber
                inputId="homePremium"
                className="w-full lg:w-13rem"
                mode="currency"
                currency="USD"
                maxFractionDigits={0}
                placeholder={currency(homePremiumDefault)}
                value={state.homePremium}
                onChange={(e) => {
                  setState({
                    ...state,
                    homePremium: e.value,
                  });
                }}
              />
            </div>
            {state.autoPremium !== null && state.homePremium !== null && (
              <div className="font-medium pt-1 pb-4">
                Total premium written: ${(state.autoPremium || 0) + (state.homePremium || 0)}
              </div>
            )}
          </div>
          <div className={`${styles.containedWidth} mb-1`}>
            <div className="font-bold text-lg mb-4">Team member cost</div>
            <div className="mb-4 flex flex-column lg:flex-row lg:align-items-center">
              <Label htmlFor="teamMemberCommissionPercentage">Commission rate (%)</Label>
              <InputNumber
                inputId="teamMemberCommissionPercentage"
                className="w-full lg:w-13rem"
                suffix="%"
                maxFractionDigits={2}
                placeholder={placeholderPercentageFormat.format(
                  teamMemberCommissionPercentageDefault,
                )}
                value={state.teamMemberCommissionPercentage}
                onChange={(e) => {
                  setState({
                    ...state,
                    teamMemberCommissionPercentage: e.value,
                  });
                }}
              />
            </div>
            <div className="mb-4 flex flex-column lg:flex-row lg:align-items-center">
              <Label htmlFor="teamMemberBaseSalary">Base monthly salary</Label>
              <InputNumber
                inputId="teamMemberBaseSalary"
                className="w-full lg:w-13rem"
                mode="currency"
                currency="USD"
                maxFractionDigits={0}
                placeholder={currency(teamMemberBaseSalaryDefault)}
                value={state.teamMemberBaseSalary}
                onChange={(e) => {
                  setState({
                    ...state,
                    teamMemberBaseSalary: e.value,
                  });
                }}
              />
            </div>
            <div className="mb-4 flex flex-column lg:flex-row lg:align-items-center">
              <Label htmlFor="payrollTaxPercentage">Payroll tax rate (%)</Label>
              <InputNumber
                inputId="payrollTaxPercentage"
                className="w-full lg:w-13rem"
                suffix="%"
                maxFractionDigits={2}
                placeholder={placeholderPercentageFormat.format(payrollTaxPercentageDefault)}
                value={state.payrollTaxPercentage}
                onChange={(e) => {
                  setState({
                    ...state,
                    payrollTaxPercentage: e.value,
                  });
                }}
              />
            </div>
          </div>
          <div className={`${styles.containedWidth} mb-1`}>
            <div className="font-bold text-lg mb-4">Agency commissions for new business</div>
            <div className="mb-4 flex flex-column lg:flex-row lg:align-items-center">
              <Label htmlFor="agencyAutoCommissionPercentage">Auto commission rate (%)</Label>
              <InputNumber
                inputId="agencyAutoCommissionPercentage"
                className="w-full lg:w-13rem"
                suffix="%"
                maxFractionDigits={2}
                placeholder={placeholderPercentageFormat.format(
                  agencyAutoCommissionPercentageDefault,
                )}
                value={state.agencyAutoCommissionPercentage}
                onChange={(e) => {
                  setState({
                    ...state,
                    agencyAutoCommissionPercentage: e.value,
                  });
                }}
              />
            </div>
            <div className="mb-4 flex flex-column lg:flex-row lg:align-items-center">
              <Label htmlFor="agencyHomeCommissionPercentage">Home commission rate (%)</Label>
              <InputNumber
                inputId="agencyHomeCommissionPercentage"
                className="w-full lg:w-13rem"
                suffix="%"
                maxFractionDigits={2}
                placeholder={placeholderPercentageFormat.format(
                  agencyHomeCommissionPercentageDefault,
                )}
                value={state.agencyHomeCommissionPercentage}
                onChange={(e) => {
                  setState({
                    ...state,
                    agencyHomeCommissionPercentage: e.value,
                  });
                }}
              />
            </div>
          </div>
          <div className={`${styles.containedWidth} mb-1`}>
            <div className="font-bold text-lg mb-4">Agency commissions for renewals</div>
            <div className="mb-4 flex flex-column lg:flex-row lg:align-items-center">
              <Label htmlFor="agencyAutoRenewalCommissionPercentage">
                Auto renewal commission rate (%)
              </Label>
              <InputNumber
                inputId="agencyAutoRenewalCommissionPercentage"
                className="w-full lg:w-13rem"
                suffix="%"
                maxFractionDigits={2}
                placeholder={placeholderPercentageFormat.format(
                  agencyAutoRenewalCommissionPercentageDefault,
                )}
                value={state.agencyAutoRenewalCommissionPercentage}
                onChange={(e) => {
                  setState({
                    ...state,
                    agencyAutoRenewalCommissionPercentage: e.value,
                  });
                }}
              />
            </div>
            <div className="mb-4 flex flex-column lg:flex-row lg:align-items-center">
              <Label htmlFor="agencyHomeRenewalCommissionPercentage">
                Home renewal commission rate (%)
              </Label>
              <InputNumber
                inputId="agencyHomeRenewalCommissionPercentage"
                className="w-full lg:w-13rem"
                suffix="%"
                maxFractionDigits={2}
                placeholder={placeholderPercentageFormat.format(
                  agencyHomeRenewalCommissionPercentageDefault,
                )}
                value={state.agencyHomeRenewalCommissionPercentage}
                onChange={(e) => {
                  setState({
                    ...state,
                    agencyHomeRenewalCommissionPercentage: e.value,
                  });
                }}
              />
            </div>
            <div className="mb-4 flex flex-column lg:flex-row lg:align-items-center">
              <Label htmlFor="clientRetentionPercentage">Client retention rate annually (%)</Label>
              <InputNumber
                inputId="clientRetentionPercentage"
                className="w-full lg:w-13rem"
                suffix="%"
                maxFractionDigits={2}
                placeholder={placeholderPercentageFormat.format(clientRetentionPercentageDefault)}
                value={state.clientRetentionPercentage}
                onChange={(e) => {
                  setState({
                    ...state,
                    clientRetentionPercentage: e.value,
                  });
                }}
              />
            </div>
          </div>
          <div className={styles.containedWidth}>
            <Button
              label="Calculate"
              className="w-full my-3"
              onClick={() => {
                analyticsContext.track('Compensation Calculator scenario calculated', {
                  scenario: computedScenarios.length + 1,
                });

                const autoPremium = state.autoPremium ?? autoPremiumDefault;
                const homePremium = state.homePremium ?? homePremiumDefault;
                const teamMemberCommissionRate =
                  state.teamMemberCommissionPercentage === null
                    ? teamMemberCommissionPercentageDefault
                    : state.teamMemberCommissionPercentage / 100;
                const teamMemberBaseSalary =
                  state.teamMemberBaseSalary ?? teamMemberBaseSalaryDefault;
                const payrollTaxRate =
                  state.payrollTaxPercentage === null
                    ? payrollTaxPercentageDefault
                    : state.payrollTaxPercentage / 100;
                const agencyAutoCommissionRate =
                  state.agencyAutoCommissionPercentage === null
                    ? agencyAutoCommissionPercentageDefault
                    : state.agencyAutoCommissionPercentage / 100;
                const agencyHomeCommissionRate =
                  state.agencyHomeCommissionPercentage === null
                    ? agencyHomeCommissionPercentageDefault
                    : state.agencyHomeCommissionPercentage / 100;
                const agencyAutoRenewalCommissionRate =
                  state.agencyAutoRenewalCommissionPercentage === null
                    ? agencyAutoRenewalCommissionPercentageDefault
                    : state.agencyAutoRenewalCommissionPercentage / 100;
                const agencyHomeRenewalCommissionRate =
                  state.agencyHomeRenewalCommissionPercentage === null
                    ? agencyHomeRenewalCommissionPercentageDefault
                    : state.agencyHomeRenewalCommissionPercentage / 100;
                const clientRetentionRate =
                  state.clientRetentionPercentage === null
                    ? clientRetentionPercentageDefault
                    : state.clientRetentionPercentage / 100;

                const autoPremiumRevenue = autoPremium * agencyAutoCommissionRate;
                const homePremiumRevenue = homePremium * agencyHomeCommissionRate;
                const totalRevenue = autoPremiumRevenue + homePremiumRevenue;
                const teamMemberCommission = (autoPremium + homePremium) * teamMemberCommissionRate;
                const teamMemberTotalCompensation = teamMemberBaseSalary + teamMemberCommission;
                const totalExpenses1Month =
                  teamMemberTotalCompensation + teamMemberTotalCompensation * payrollTaxRate;
                const netProfit1Month = totalRevenue - totalExpenses1Month;

                const scenarioCore: ScenarioCore = {
                  autoPremium,
                  homePremium,
                  teamMemberCommissionRate,
                  teamMemberBaseSalary,
                  payrollTaxRate,
                  agencyAutoCommissionRate,
                  agencyHomeCommissionRate,
                  agencyAutoRenewalCommissionRate,
                  agencyHomeRenewalCommissionRate,
                  clientRetentionRate,
                  autoPremiumRevenue,
                  homePremiumRevenue,
                  totalRevenue,
                  teamMemberCommission,
                  teamMemberTotalCompensation,
                  totalExpenses1Month,
                  netProfit1Month,
                };
                const scenario: Scenario = {
                  ...scenarioCore,
                  ...computeScenario6MonthRenewal(scenarioCore),
                };
                setComputedScenarios((cs) => [...cs, scenario]);
                setIsAddingVariation(false);
              }}
            />
            <Button
              label="Reset"
              className="w-full p-button-outlined"
              onClick={() => {
                setState(defaultScenario());
              }}
            />
          </div>
        </Fragment>
      ) : (
        <div className={styles.containedWidth}>
          <Button
            label="Add another scenario"
            className="w-full my-3"
            onClick={() => {
              analyticsContext.track('Compensation Calculator scenario added');
              setIsAddingVariation(true);
            }}
          />
        </div>
      )}
    </div>
  );
};
